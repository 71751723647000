
  .ContainerFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ContainerPayment {
    display: flex;
    flex-direction: column;
    margin: 70px 0 30px;
  }

  .Right{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  .Left{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    box-shadow: inset 10px 0 30px 0 #dce7f9;
    background-color: #e9f2fe;
    height: 1024px;
  }

  .Logo {
    display: flex;
    margin: 70px 0 30px;
  }

  .TitleUser{
    font-family: ModernEra;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #252525;
    margin: 15px;
  }

  .TitleCheckout{
    font-family: ModernEra;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #252525;
    margin: 30px 0 15px;
  }

  .checkoutImage {
    width: 95%;
    margin-left: 5%;
    height: auto;
    object-fit: contain;
  }
  .col1 {

    width: 100%;
    height: 100%;
    order: 1;
  }
  .col2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    order: 2;
  }
  .productInformation{
    margin-left: 5%;
    margin-right: 15%;
    align-items: center;
    height: 30%;
  }

  .productInformation ul {
    padding-inline-start: 10%;
  }
  .footerFix {
    display: block;
    height: 10vh;
  }

.titleInformation{
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #252525;

}

.subtitleInformation{
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
  margin-bottom: -2%;
}
li{
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #2a2a2a;
}

.background {
  background-color: #ffffff;
}

.Square {
  display: none;
}

.Mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  justify-content: center;
}

.Desktop{
  display: none;
}

@media (min-width:700px) {
  .ContainerFlex {
    display: flex;
    flex-direction: column;
  }

  .ContainerPayment {
    display: flex;
    flex-direction: column;
    margin: 45px 0 30px;
  }

  .Logo {
    margin: 120px 0 0;
  }

  .Mobile{
    display: none;
  }
  
  .Desktop{
    display: flex;
    flex-direction: row;
    margin: 0 15px;
    justify-content: space-between;
    width: 100%;
  }

  .checkoutImage {
    width: 95%;
    margin-left: 5%;
    height: auto;
    object-fit: contain;
  }

  .productInformation{
    margin-left: 5%;
    margin-right: 15%;
    align-items: center;
    height: 30%;
  }
  .FooterIcons{
    display: flex;
    margin-top: 5%;
    margin-left: 40%;
    margin-right: 15%;
    align-content: center;
    flex-direction: column;
  }

  .productInformation ul {
    padding-inline-start: 10%;
  }
}
@media(min-width: 860px){
  .Square {
    display: inline;
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    position: absolute;
    margin-left: 48%;
    margin-top: 476px;
    transform: rotate(45deg);
  }
}

@media(min-width: 1000px){
  .productInformation{
    display: flex;
    margin-left: 35%;
    margin-right: 20%;
    flex-direction: column;
  }
}
