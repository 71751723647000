@media(min-width: 800px){
  .error {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    justify-content: center;
    padding-bottom: 30px;
  }
  
}

@media(max-width: 799px){
  .error {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .error img {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    object-fit: contain;
    margin: 0px auto;
  }
}

.ErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Logo {
  display: flex;
  margin: 70px 0 30px;
}

.TryAgain{
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  padding: 14px 30px 15px;
  background-color: #0752f9;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.error h1 {
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.error h2 {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
}
.error picture {
  padding-top: 10%;
  text-align: center;
  padding-bottom: 10%;

}

.error .buttonContainer{
  text-align: center;
}

.buttonContainer{
  margin-top: 20px;
}
