
@media(min-width: 800px){
  .success {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    padding-bottom: 30px;
  }
 
  .codeButton {
    display: inline-block;
    border-radius: 8px;
    box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
    background-color: #0752f9;
    font-family: ModernEra;
    font-weight: bold;
    font-size: 15px;
    color: #fff;
    align-items: center;
    text-align: center;
    padding: 15px 30px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    width: 25%;
  }
}

@media(max-width: 799px){
  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .success img {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    object-fit: contain;
    margin: 0px auto;
  }
  .codeButton {
    display: inline-block;
    border-radius: 8px;
    box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
    background-color: #0752f9;
    font-family: ModernEra;
    font-weight: bold;
    font-size: 15px;
    color: #fff;
    align-items: center;
    text-align: center;
    padding: 15px 0px;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    width: 25%;
  }
}
.success h1 {
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.success h2 {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
}

.Logo {
  display: flex;
  margin: 70px 0 30px;
}


.success picture {
  padding-top: 10%;
  text-align: center;
  padding-bottom: 10%;
}

