.inputContainer {
  margin-bottom: 1rem;
  width: 100%;
}

.onlyDesktop {
  display: none;
}

.errorMessage {
  color: #dc3545;
  font-style: italic;
}

.backButton {
  margin-top: 30px;
  text-decoration: underline;
  color: #666;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.ActionButton {
  display: inline-block;
  border-radius: 10px 10px 0 0;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #333;
  font-family: ModernEra;
  font-weight: bold;
  font-size: 15px;
  color: #1e1e1e;
  background-color: #ffffff;
  align-items: center;
  text-align: center;
  width: 50%;
  height: 44px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.Active {
  background-color: #0752f9;
  color: #ffffff;
}

.SubmitButton{
  outline: 0;
  border-radius: 8px;
  border: solid 1px #333;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  padding: 14px 30px 15px;
  background-color: #0752f9;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.FormSubmitButton{

}

.NoActive {
  background-color: #ffffff;
}

.payButton:focus {
  outline: none;
}

.payButtonBig {
  width: 250px;
}

.FormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DisplayNone {
  display: none;
}

.ErrorMessage {
  color: red;
  font-size: 14px;
}

.Title {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.cardTitle {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}


.ContainerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeightContainer {
  min-height: 300px;
}

.TextCenter {
  text-align: center;
}

.ButtonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-family: "ModernEra";
  display: flex;
  justify-content: center;
  align-items: center;
}

.ButtonNextCita {
  width: 189px;
}



.Form {
  width: 100%;
  margin-bottom: 5%;
}

.Input {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;
  font-family: "ModernEra";
}
.Input .Label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
}
.Input .Border {
  position: absolute;
  bottom: -17px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
}
.Input input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
}

.InputElement{
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
}
.Input input:hover {
  background: rgba(34, 50, 84, 0.03);
  box-sizing: border-box;
}
.Input input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.Input input:focus {
  background: none;
  outline: none;
  box-sizing: border-box;
}
.Input input:focus + span {
  color: #07f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.Input input:focus + span + .Border {
  transform: scaleX(1);
  box-sizing: border-box;
}

.SpanCard span{
  color: #07f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.CardBorder span.Border{
  transform: scaleX(1);
  box-sizing: border-box;
}

.Error {
  color: red;
  margin-left: 20px;
  margin-top: -20px;
  text-align: left;
  font-family: "ModernEra";
}

.TitleModernEra {
  font-family: "ModernEra";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.TitleCooperBT {
  font-family: "CooperBT";
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 800px) {
  .Container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 36px;
    width: 407px;
  }
  .marginForm {
    margin-left: 15%;
    margin-right: 25%;
  }
  .NextContainer {
    padding-top: 30px;
  }

  label {
    font-size: 16px;
  }
}

@media (max-width: 799px) {
  .Container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 350px;
    padding-top: 10px;
  }

  .marginForm {
    margin-left: 5%;
    margin-right: 5%;
  }
  .NextContainer {
    position: relative;
    bottom: -20px;
  }

  label {
    font-size: 16px;
  }
  .Form {
    width: 100%;
    margin-bottom: 10%;
    margin-top: 5%;
  }
  
}

@media (max-width: 350px) {
  .Container {
    width: 300px;
    padding-top: 10px;
  }
}

.CenterLoader {
  padding-top: 25%;

}
