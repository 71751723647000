
.ContainerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
}

.HeightContainer {
  min-height: 300px;
}

.TextCenter {
  text-align: center;
}

.ButtonNext {
  width: 136px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  font-family: "ModernEra";
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.ButtonNextCite {
  width: 189px;
}

.Container .Form{
  margin-top: 20px;
  margin-bottom: 20px;
}

.Container  {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 10px;
}


.Inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;
  font-family: "ModernEra";
}

.Form {
  /* display: flex; */
}

.Inp .Label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
  z-index: -1
}
.Inp .Border {
  position: absolute;
  bottom: -17px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
}
.Inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
}
.Inp input:hover {
  background: rgba(34, 50, 84, 0.03);
  box-sizing: border-box;
}
.Inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.Inp input:focus {
  background: none;
  outline: none;
  box-sizing: border-box;
}
.Inp input:focus + span {
  color: #07f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.Inp input:focus + span + .Border {
  transform: scaleX(1);
  box-sizing: border-box;
}

.Error {
  color: red;
  margin-top: -20px;
  text-align: left;
  font-family: "ModernEra";
}

.TitleModernEra {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.TitleCooperBT {
  font-family: CooperBT;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

label {
  font-size: 16px;
}

.DataCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DataCard .Form {
  /* width: 155px; */
}

.DataCardContainer {
  display: flex;
  flex-direction: column;
  width: 155px;
}

@media (max-width: 350px) {
  .ContainerFlex {
    width: 300px;
  }

  .DataCardContainer {
    width: 135px;
  }

  .DataCard .Form {
    width: 142px;
  }
}

@media (min-width: 800px) {
  .Container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 20px;
  }

  .DataCardContainer {
    display: flex;
    flex-direction: column;
    width: 185px;
  }

  .ContainerFlex {
    width: 407px;
  }

}

