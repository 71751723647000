
.InfoContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-top: 266px;
  border-radius: 10px;
}

.ContainerResume{
	width: 100%;
}

.ContainerPrice{
  margin: 0 15px
}

.Bold {
  font-weight: bold;
  margin: 0;
}

.DescriptionResume{
  margin: 0 15px;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #000000;
}

.ItemPrice{
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ItemTitle{
  font-family: ModernEra;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}

.ItemAmount{
  font-family: ModernEra;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
}

.ItemDiscount{
  color: #e02020;
}

.ItemTotal{
  font-weight: 800;
}

.Separator{
  border: solid 1px #e0e0e0;
  margin: 30px 15px;
}

.TextTitle {
  text-align: center;
  margin-left: 15px;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #252525;
}
  
.TitleResume > img{ 
  margin-right: 15px;
}

.TitleResume{
  height: 57px;
	display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}

@media (max-width: 350px) {
  .InfoContainer {
    width: 300px;
  }
} 