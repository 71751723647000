  .InstallmentsContainer {
  }
  
  .ThemesMobileContainer{
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 0 0 30px;
  }
  
  .TopicSelector{
	display: flex;
	flex-direction: column;
	outline: none;
	width: 350px;
	border-radius: 21px;
	box-shadow: inset 0 0 20px 0 rgba(169, 192, 221, 0.4);
  }
  
  .TextTema {
	height: 58px;
	text-align: center;
	display: flex;
	align-items: center;
	margin: 0 5%;
  }
  
  .TextTema > img{ 
	margin-left: auto;
  }
  
  .TopicSelector > ul, .TopicDesktopSelector > ul {
	list-style: none;
	padding: 0;
	margin: 0;
	transition: max-height .5s ease 0s;
  }
  .TopicSelector > ul > li , .TopicDesktopSelector > ul > li{
	padding: 8px 12px;
	transition: max-height .5s ease 0s;
  }
  
  .TopicSelector > ul > li:hover {
	background-color: rgba(0, 0, 0, 0.14);
	cursor: pointer;
  }

  @media (max-width: 350px) {
	.TopicSelector {
	  width: 300px;
	}
  }

  @media (min-width:800px) {
	.TopicSelector{
	   width: 407px;
	}
  }
 
  
